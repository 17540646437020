@import "../base/mixins";



.google-translate {
	margin: 5px 0;
	select {
		margin-bottom: 0;
		display: inline-block;
	}
	a.gtflag {
		font-size: 13px !important;
	}

}


.contact-details-block {
	border-left: 1px solid grey;
	padding-left: 30px;
}


.awards-block,
.bean-awards-block {
	margin: 50px 0;
	h2 {
		margin-bottom: 20px;
	}
	.field-name-field-link-image {
		display: inline-block;
		padding-right: 20px;
		padding-bottom: 30px;
	}
}

.important-announcement {
	@include border;
	padding: 10px;
	margin-top: 30px;
	h2 {
		color: $red;
		text-transform: uppercase;
	}

}

.block-masquerade {
	display: none;
	form {
		background: transparent;
	}
	@include media($tablet) {
		display: inline-block;
		width: 300px;
	}
	#edit-masquerade-user-field {
		max-width: 180px;
		display: inline-block;
	}
	ul li {
		display: inline-block;
		margin: 0 0 0 10px !important;
	}
	.form-item {
		margin: 0;
	}
	h2 {
		font-size: 1.2em;
	}
	.form-submit {
		padding: 0.6em 1em;
	}
}
.front-page-slideshow {
	margin-top: 15px;
}


.letter-block {
	.view-footer p {
		margin: 0;
		text-align: right;
	}
}

.tagclouds-term {
	margin: 0 0 10px 10px;
}




.logged-in-user {
	.field-name-field-first-name {
		display: inline-block;
	}
	.views-field-name {
		font-size: 1.3em;
		margin-bottom: 20px;
	}
}



.staging-site-header h4 {
	color: grey;
	font-weight: normal;
	font-size: 1.2em;
}

#disqus-thread {
	margin-top: 70px !important;
}


