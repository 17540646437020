body {
	background: $body-bg-color;
	margin: 0;
}

#messages,
.main-wrapper {
	padding: $grid-padding;
}

.topbar-wrapper {
	overflow: visible;
	background: $top-bar-color;
	padding: $grid-padding;
}

.header-wrapper {
	background: $header-wrapper-color;
	padding: $grid-padding;
	height: $header-height;
	@include media($mobile) {
		display: none;
	}
	@include media($laptop) {
		display: block;
	}
}

.navigation-wrapper {
	background: $navigation-wrapper-color;
	padding: $grid-padding;
	@include media($mobile) {
		display: none;
	}
	@include media($laptop) {
		display: block;
	}
	//@include boxshadow;
}



///////////////////////////////////////////////

// Featured & Preface Area

#featured,
#preface {
	@include clearfix;
	.span-columns-8 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(8);
			@include omega(2n);
		}
	}

	.span-columns-6 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(6);
			@include omega(2n);
		}
	}
	.span-columns-4 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(4);
			@include omega(3n);
		}
	}
	.span-columns-3 {
		@include span-columns(12);
		@include media($laptop) {
			@include span-columns(6);
			@include omega(2n);
		}
		@include media($desktop) {
			@include omega-reset(2n);
			@include span-columns(3);
			@include omega(4n);
		}
	}
}

.featured-wrapper {
	padding: $grid-padding;
	padding-top: 1em;
  padding-bottom: 1em;
	background: $featured-bg-color;
}

.preface-wrapper {
	padding: 10px 0px;
	background: $preface-bg-color;
}




///////////////////////////////////////////////

// Content Area

#content {
	padding: 10px 0 0;
	@include media($tablet) {
		padding: 10px 0 1em;
	}
	@include media($laptop) {
		//min-height: 400px;
	}
}

.front #content {
	padding: 0;
}
.front main  {
	@include media($mobile) {
	}
	@include media($laptop) {
		padding-top: 0;
	}
}

.not-front #sidebar-second {
	@include media($laptop) {
		//padding-top: 38px;
	}
}

.not-front #sidebar-second {
	@include media($desktop) {
		//padding-top: 58px;
	}
}



#sidebar-second .block:last-child { // insert some bottom margin
  margin-bottom: 30px;
}

///////////////////////////////////////////////

// Footer

.footer-wrapper {
	//overflow: auto;
	padding: $grid-padding;
	background: $footer-bg-color;
	a:hover {
		text-decoration: underline;
	}

	.footer-one {
		padding: 1em 0;
	}
	.footer-two {
		padding: 1em 0;
		@include media($tablet) {
			.block {
				display: block;
				text-align: right;
			}
		}
	}
}






#site-footer {
  position: relative;
  color: $footer-text-colour;
  a {
    color: $footer-text-colour;
  }
  .easable-credit {
    @include media($laptop) {
      position: absolute;
      bottom: 10px;
      right: 0px;
    }
  }
}


// Sticky Footer
@include media($laptop) {
	.page {
		display: flex;
		min-height: 100vh;
	  	flex-direction: column;
	}

	.main-wrapper {
		//flex: 1;
	}
}
