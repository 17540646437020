//////////////////////////////////////////////////////////
// 1. Base menu styles
// 2. Main Menu
// 3. Mobile Menu
// 4. User Menu
// 5. Breadcrumbs
// 6. Nice Menu
// 7. OG Menu
//////////////////////////////////////////////////////////

// 1. Base menu styles

.horz-menu {
  ul.menu li {
    display: inline-block;
    //margin: 0 0.5em;
  }
  ul.menu li:last-child,
  li:last-child {
    padding-right: 0;
  }
}

.horz-menu-buttons {
  ul.menu li {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 1px solid grey;
    a {
      display: block;
      padding: 5px 20px;
    }
  }
}

//////////////////////////////////////////////////////////

// 2. Main Menu

.main-menu {
  ul {
    margin-right: -0.5em;
  }
  @include media($tablet) {
    font-size: 1em;
  }
  @include media($desktop) {
    font-size: 1.3em;
  }
  @include media($highdef) {
    font-size: 1.4em;
  }
  li {
    a {
      display: block;
      padding: 0 0.5em;
    }
  }
	a:link,a:visited {
		color: $main-menu-link-color;
	}
	a:hover,a:active,a:focus {
		color: $main-menu-hover-link-color;
	}

}

//////////////////////////////////////////////////////////

// 3. Mobile Menu

/* Important - mean menu does not work without an expanded menu to call */
//#block-system-main-menu {
//    visibility: hidden;
//}



.mean-container .mean-bar {
  background-repeat: no-repeat;
  background-position: left;
  padding: 0;
  border-bottom: 1px solid $base-border-color;
  float: none;
  height: $mobile-header-height;
  background-image: $mobile-logo;
}

.mean-container .mean-nav {
  background: $mobile-menu-bg-color;
  font-size: 1em;
  font-family: $header-font;
  font-weight: 700;
  margin-top: $mobile-header-height;
}
.mean-container .mean-nav ul li a{
  color: $hamburger-color;
}

#hamburger span,
#hamburger span:before,
#hamburger span:after {
  cursor: pointer;
  border-radius: 2px;
  height: 5px;
  width: 35px;
  background: $hamburger-color;
  position: absolute;
  display: block;
  content: '';
  right: 10px;
  margin-right: 5px;
}
#hamburger span:before {
  top: 10px;
  right: 0;
  margin-right: 0;
}
#hamburger span:after {
  bottom: -20px;
  right: 0;
  margin-right: 0;
}

.mean-bar:before {
  content: "MENU";
  color: $hamburger-color;
  position: absolute;
  right: 14px;
  top: 45px;
  font-size: 80%;
}
.mean-container .mean-nav ul li a {
  padding: 0.8em 5%;
}
.mean-nav ul li a.mean-expand:hover {
  background: $main-menu-hover-color !important;
}

a.mean-expand:hover {
  background: $main-menu-hover-color;
}


//////////////////////////////////////////////////////////

// 4. User Menu

.user-menu {
  margin: 7px 0;
  font-size: 80%;
  li {
  padding: 5px;
  }
	a:link,a:visited {
		color: $user-menu-link-color;

	}
	a:hover,a:active,a:focus {
		color: $user-menu-hover-color;
	}
}




//////////////////////////////////////////////////////////

// 5. Breadcrumbs

.breadcrumb {
  margin: 20px 0 10px;
  a:after {
    content: '»';
    padding: 0 10px;
  }

a:last-child:after {
      //content: '';
    }
}

//////////////////////////////////////////////////////////
// 6. Nice Menus

// Variables
$nice-sub-menu-width: 200px; // Sets variable for sub menu width
$nice-sub-menu-offset: 5px;


/******************************
 Global CSS for ALL nice menu types
******************************/
.nice-main-menu {

  @include equal-width-horz-list-items;
  ul.nice-menu {
    text-transform: uppercase;
    min-width: 100%
  }
  ul.nice-menu,
  ul.nice-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 0;
  }

  ul.nice-menu li {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid $main-menu-link-color;
    border-right: 0;
    text-align: center;
    float: left; /* LTR */
    background-color: $main-menu-background-color;
    @include media($phablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 20px;
    }
    &:last-child {
      border-right: 1px solid $main-menu-link-color;
    }
    &:hover {
      background-color: $main-menu-hover-color;
    }
  }


  ul.nice-menu a {
    padding: 0.3em 5px 0.3em 5px;
    color: $main-menu-link-color;
    &:hover {
      color: $main-menu-hover-link-color;
    }

  }

  ul.nice-menu ul {
    top: 1.8em;
    left: -1px; /* LTR */
    border: 0;
  }

  ul.nice-menu ul li {
    width: $nice-sub-menu-width !important;
    text-align: left;
    margin-left: -1px;
    border-bottom: 1px solid $main-menu-link-color;
    border-left: 1px solid $main-menu-link-color;
    border-right: 1px solid $main-menu-link-color;
  }
  ul.nice-menu ul li:first-child {
    margin-top: $nice-sub-menu-offset;
  }

  ul.nice-menu ul li a {
    @include media($phablet) {
      font-size: 14px;
    }
    @include media($desktop) {
      font-size: 16px;
    }
  }

  /******************************
   VERTICAL (left/right) menus
  ******************************/

  /* This is the default width of all vertical menus. */
  ul.nice-menu-right,
  ul.nice-menu-left,
  ul.nice-menu-right li,
  ul.nice-menu-left li {
    width: $nice-sub-menu-width;
  }

  /* VERTICAL menus where submenus pop RIGHT (default). */
  ul.nice-menu-right ul {
    width: $nice-sub-menu-width;
    left: $nice-sub-menu-width;
    top: -1px;
  }

  ul.nice-menu-right ul ul {
    width: $nice-sub-menu-width;
    left: $nice-sub-menu-width;
    top: -1px;
  }

  ul.nice-menu-right li.menuparent,
  ul.nice-menu-right li li.menuparent {
    background: $main-menu-background-color url("../images/nice-menu/arrow-right.svg") right center no-repeat;
  }

  ul.nice-menu-right li.menuparent:hover,
  ul.nice-menu-right li li.menuparent:hover {
    background: $main-menu-hover-color url("../images/nice-menu/arrow-right.svg") right center no-repeat;
  }

  /* VERTICAL menus where submenus pop LEFT. */
  ul.nice-menu-left li ul {
    width: $nice-sub-menu-width;
    left: -12.65em;
    top: -1px;
  }

  ul.nice-menu-left li ul li ul {
    width: $nice-sub-menu-width;
    left: -12.65em;
    top: -1px;
  }

  ul.nice-menu-left li.menuparent,
  ul.nice-menu-left li li.menuparent {
    background: $main-menu-background-color url("../images/nice-menu/arrow-left.svg") left center no-repeat;
  }

  ul.nice-menu-left li.menuparent:hover,
  ul.nice-menu-left li li.menuparent:hover {
    background: $main-menu-hover-color url("../images/nice-menu/arrow-left.svg") left center no-repeat;
  }

  ul.nice-menu-left a,
  ul.nice-menu-left ul a {
    padding-left: 14px;
  }

  /******************************
   HORIZONTAL (down) menus
  ******************************/

  ul.nice-menu-down {
    float: left; /* LTR */
    border: 0;
  }

  ul.nice-menu-down li {
    border-top: 0 solid $main-menu-hover-color;
  }

  ul.nice-menu-down li li {
    border-top: 0;
  }

  ul.nice-menu-down ul {
    left: 0; /* LTR */
  }

  ul.nice-menu-down ul li {
    clear: both;
  }

  ul.nice-menu-down li ul li ul {
    left: $nice-sub-menu-width; /* LTR */
    top: -1px;
  }

  ul.nice-menu-down .menuparent a {
    padding-right: 15px; /* LTR */
  }

  ul.nice-menu-down li.menuparent {
    background: $main-menu-background-color url("../images/nice-menu/arrow-down.svg") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li.menuparent:hover {
    background: $main-menu-hover-color url("../images/nice-menu/arrow-down.svg") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li li.menuparent {
    background: $main-menu-background-color url("../images/nice-menu/arrow-right.svg") right center no-repeat; /* LTR */
  }

  ul.nice-menu-down li li.menuparent:hover {
    background: $main-menu-hover-color url("../images/nice-menu/arrow-right.svg") right center no-repeat; /* LTR */
  }


}
