////////////////////////////////////////////////

// Anti-FOUT technique for easing in Typekit web fonts
// http://richardsweeney.com/a-suggested-fix-for-the-dreaded-fout/

/*
h1, h2, h3, h4, h5, h6, p, ul, ol, dl, span, a {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.wf-active {
  h1, h2, h3, h4, h5, h6, p, ul, ol, dl, span, a {
    opacity: 1;
  }
}

.wf-inactive {
  h1, h2, h3, h4, h5, h6, p, ul, ol, dl, span, a {
    opacity: 1;
  }
}
*/

////////////////////////////////////////////////

// @FONT-FACE

//@import 'https://fonts.googleapis.com/css?family=Roboto:300,400,700';

$header-font: "eurostile",sans-serif;
$menu-font: "eurostile",sans-serif;
$body-font: roboto, sans-serif;

////////////////////////////////////////////////

//Default link colors

a:link, a:visited {
  color: $link-color;
}
a:hover, a:active, a:focus {
  color: $link-color-hover;
}

////////////////////////////////////////////////

//Base typography

body {
  font-size: 18px;
  font-family: $body-font;
  color: $body-font-color;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0 0 1em 0;
  @include media($tablet);
}


// Fix for crappy type in admininstration menu
#admin-menu,
.user-menu {
  -webkit-font-smoothing: subpixel-antialiased !important;
}

////////////////////////////////////////////////

//Headings

//Based on 1.250 Major Third pattern

h1,h2,h3,h4 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: $header-font;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}


h1 {
font-size: 1.562em;
font-weight: 700;
}
h2 {
  font-size: 1.25em;
  font-weight: 500;
}
h3 {
  font-size: 1em;
  font-weight: 500;
}
h4 {
  font-size: 1em;
  font-weight: 500;
}


@include media($desktop) {
  h1 {
    font-size: 2.441em;
  }
  h2 {
    font-size: 1.953em;
  }
  h3 {
    font-size: 1.562em;
  }
  h4 {
    font-size: 1.25em;
  }
}


////////////////////////////////////////////////

// Main Menu

.nice-main-menu,
.main-menu {
  font-family: $menu-font;
  font-weight: 300 !important;
  letter-spacing: 0.1em;
}

////////////////////////////////////////////////

// Superscript
sup {
  font-size: 0.7em;
}



// Blockquote

blockquote {
  margin-left: 5px;
  padding:  0.4em 0 0.01em 2em;
  font-style: italic;
  font-family: $body-font;
  font-size: 1.7em;
  background-image: url('../images/quotemarks.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  strong {
    font-weight: bold;
  }
  p {
    //padding-left: 20px;
  }
}

////////////////////////////////////////////////

// Lists

ul {
  list-style: disc inside none;
}
ol {
  list-style: decimal inside none!important;
}

.node ul,
.node ol {
  margin-bottom: 1em;
  li {
    margin-bottom: 0.3em;
  }
}





.view-footer {
  li {
    margin-bottom: 0.3em;
  }
}

.item-list ul {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0;
  }
}

.node-teaser .field-name-field-slideshow-image,
.card,
.tile {
li {
    margin-bottom: 0px!important;
  }
}


