//////////////////////////////////////////////////

// Hide the toolbar or admin menu on small screens.

@media all and (max-width: 800px) {
  #toolbar, #admin-menu {
    display: none;
  }

  html {
    body.toolbar, body.admin-menu {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
}
//////////////////////////////////////////////////

// Fix to prevent unwanted contextual links always showing in views rows

.views-row .contextual-links-wrapper.contextual-links-processed {
    display: none;
}

.field-name-field-related-content .contextual-links-wrapper.contextual-links-processed {
    display: none;
}

//////////////////////////////////////////////////

// Prevents node content area with floated inline images breaking mini-gallery

.not-front #content .field-name-body {
  //overflow: auto;
}

//////////////////////////////////////////////////

// Remove submitted information form webforms

.node-type-webform  {
  .posted {
    display: none;
  }
}

//////////////////////////////////////////////////

// Converts node level edit content tabs to contextual links
// Also requires custom class in html.tpl.php around line 69

.node-cogwheel {
   ul.contextual-links li a {
      padding-right: 3em;
   }
   a.active {
      display: none;
   }
}


//////////////////////////////////////////////////

// Disbles pointer events for google map footer - prevent unwelcome scrolling.
// Ref: http://stackoverflow.com/questions/21992498/disable-mouse-scroll-wheel-zoom-on-embedded-google-maps

.bean-google-map-block{
  line-height: 0;
}

.simple-gmap-nozoom-overlay {
  background: transparent;
  position: relative;
  width: 100%; /* map width */
  height: 450px; /* map height */
  margin-bottom: -450px;  /* -map height */
}

//////////////////////////////////////////////////

// Removes unwanted horizontal scroll caused by
// external link icon when justified right in footer

.footer-two span.ext {
  display: none;
}

//////////////////////////////////////////////////

// Filedepot fixes

#filedepot {
  .filedepottoolbar  {
    height: 45px;
  }

  #tagspanel {
    margin-left: -328px;

    .underlay {
      display: none;
    }

  }


}
.tagsearchboxcontainer {
    width: 20% !important;
}
.yui-layout {
  height: 500px !important;
}
.filedepottoolbar_searchform table {
    background-color: transparent !important;
}
.reportheadercontainer {

}

.yui-layout-bd {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.pluginReportTitle {
  margin-top: -10px !important;
  margin-left: -50px !important;
}


//////////////////////////////////////////////////

// Remove RSS icon from groups nodes when displaying in views

.section-box,
.important-announcement,
.noticeboard {
  .feed-icon {
    display: none;
  }
}

//////////////////////////////////////////////////

// Remove default color as fieldsets
fieldset {
  background-color: white;
}


//////////////////////////////////////////////////

// Remove default color as fieldsets
input#edit-pass-pass1,
input#edit-pass-pass2 {
  width: 50%;
}


a.tabledrag-handle .handle {
    height: 25px;
    margin: -0.4em 0.5em;
    padding: 0.42em 1.5em;
}

//////////////////////////////////////////////////

// Remove weight selector from draggable views

.draggableviews-weight {
  display: none !important;
}


//////////////////////////////////////////////////

// Resolves paragraph indentation in view footers

.view-footer,
.view-empty,
.view-header {
  p {
    padding: 0;
  }
}

//////////////////////////////////////////////////

// Fixes problem with contextual link font size

.contextual-links-wrapper.contextual-links-processed li {
  line-height: 1em;
  font-size: 14px !important;
}


//////////////////////////////////////////////////

// Fixes problem with Flexslider prev next icons showing text values

.flexslider {
  .flex-prev,
  .flex-next {
    line-height: 1.5em;
  }
}




