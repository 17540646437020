// Variables
$header-height: 90px;
$mobile-header-height: 80px;
$mobile-logo: url(../images/logo-mobile.svg);

// Header
.topbar-wrapper {
  color: $top-bar-text-color;
  font-size: 0.9em;
}

.logo-block {
  margin-top: 18px;
  color: $yellow;
}


.header-wrapper {
  background: rgba(0, 88, 49, 0.9) !important;
}

.header-contact-block {
  color: #FFF;
  font-family: $menu-font;
  font-weight: 300;
  letter-spacing: 0.05em;
  a {
    color: #FFF;
    font-family: $header-font;
    font-weight: 300;
  }
}

// Hero

#site-header {
  z-index: 1;
}

.front {
  #hero {
    margin-top: -132px;
  }
}


.bean-hero-block {
  h2 {
    background: rgba(0, 0, 0, 0.4);
    display: table;
    padding: 0 10px;
  }
  p {
    background: rgba(0, 0, 0, 0.4);
    display: table;
    padding: 0 10px;
    text-transform: uppercase;
  }
}



// Preface
.preface-right {
  padding-bottom: 20px;
  .button-style-2 a {
    margin-top: 20px;
    font-size: 1.3em;
    padding: 1em 2em;
  }
}


.front {
  #row1 {
    padding:  50px 14px;
    @include media($laptop) {
      padding:  120px 14px 110px;
    }
    background: $palegrey url("../images/");
    background-size: cover;
    background-position: center;
    h2 {
      color: white;
      text-align: center;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      text-shadow: 2px 2px 6px $charcoal;
    }
    .field-name-field-block-text {
      font-weight: 300;
      text-align: center;
      color: white;
      text-shadow: 2px 2px 6px $charcoal;
    }
    @include media($laptop) {
      h2 {
        text-align: center;
        padding: 0 5%;
      }
      .field-name-field-block-text  {
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}


.bold-header h2 {
  font-weight: 700;
  font-size: 1.562em;
  @include media($laptop) {
    font-size: 2em;
  }
  @include media($desktop) {
    font-size: 2.929em;
  }
}


// View modes
.card {
  text-align: center;
  h2 {
    font-size: 1.5em;
  }
  a {
    color: $lightgreen;
  }
}

.vehicle-details {
  .field-name-field-price {
    display: inline-block;
  }
  .views-field-name,
  .condition-wrapper,
  .price-wrapper,
  .mileage-wrapper {
    margin-bottom: 20px;
    display: block;
  }
  .field-name-field-mileage,
  .field-name-field-condition {
    display: inline;
  }
  .views-field-description p {
    margin: 1em 0;
    padding: 0;
  }
  .views-field-field-external-links {
    ul {
      padding: 0;
    }
    li {
      list-style-type: none;
    }
  }
}

// Views


// Footers
.public-transport-block {
  font-size: 0.8em;
  color: $lightgrey;
}

.footer-wrapper {
  color: white;
  a {
    color: white;
  }
}

// Sales Status 
.vehicle-details .vehicle-status {margin-bottom: 20px;}


