//Hero depth classes
.hero-half {
  .bean-hero-block {
    height: calc(100vh - 65px);
    @include media($laptop) {
      height: calc(50vh - 34px);
    }
  }
}
.hero-two-third,
.hero-two-thirds {
  .bean-hero-block {
    height: calc(100vh - 65px);
    @include media($laptop) {
      height: calc(66vh - 34px);
    }
  }
}
.hero-full {
  .bean-hero-block {
    height: calc(100vh - 65px);
    @include media($laptop) {
      height: 600px;
      height: calc(100vh - 34px);
    }
  }
}


//Hero layout
.hero-wrapper,
	#content {
		.bean-hero-block {
      display: block;
			position: relative;
			@include media($laptop) {
				//background-position: center -10px;
			}
		.content {
			@include outer-wrapper;
      //padding-top: 15px;
      }
      @include media($mobile) {
        padding-top: 65px;
      }
      @include media($laptop) {
        padding-top: 200px;
			}
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 2px rgba(150, 150, 150, 2);
				margin-bottom: 20px;
			}
			p {
				font-size: 1.5em;
        letter-spacing: 0.1em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
        font-family: $menu-font;
			}
			li {
				margin: 20px 0px;
				a {
					color: white;
          font-family: $menu-font;
          text-transform: uppercase;
					font-size: 2em;
					@include button-style-3;
				  @include media($highdef) {
				}
			}
		}
	}
}


.featured-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			//height: 340px;
			//padding: 1em;
		}
		@include media($laptop) {
			//height: 300px;
			//padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 5px rgba(150, 150, 150, 2);
				margin-bottom: 0;
			}
			p {
				font-size: 1.3em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}


.preface-wrapper,
#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		margin-bottom: 2em;
		@include media($mobile) {
			height: 340px;
			padding: 1em;
		}
		@include media($laptop) {
			height: 300px;
			padding: 2em;
		}
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
				margin-bottom: 0;
			}
			p {
				font-size: 1.3em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
			}
			ul {
				bottom: 2em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
	}
}



#content  {
	.bean-hero-block {
		position: relative;
		margin-top: 10px;
		.content {
			color: $hero-text-color;
			h2 {
				font-size: 2.441em;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
				margin-bottom: 0;
			}
			p {
				font-size: 200%;
				text-shadow: 0px 0px 1px rgba(150, 150, 150, 2);
			}
			ul {
				margin-top: 0.5em;
			}
			li {
				@include button-style-1;
				display: inline-block;
				margin: 10px 10px 10px 0;
				a {
					color: white;
				}
			}
		}
		@include media($mobile) {
			height: 340px;
			padding: 0em 1em;
			font-size: 90%;
		}
		@include media($laptop) {
			height: 300px;
			padding: 0em 1em;
			font-size: 120%;
		}
	}
}


// Captions and CTA
.bean-hero-block {
  display: flex;
  .content {

    width: 100%;
    p {
      margin: 0;
      color: white;
    }
    h2 {
      color: white;
      font-weight: 700;
      @include media($mobile) {
        margin-top: 1em;
        font-size: 2em;
      }
      @include media($tablet) {
        font-size: 3em;
      }
    }
    ul{
      list-style-type: none;
      li {
        display: inline-block;
      }
      font-size: 1.8em;
    }
  }
}

.node-type-section {
  .bean-hero-block {
    h2 {
      margin-top: 150px;
      max-width: 600px;
    }
  }
}

